<template lang="">
  <div>
    <LoaderLinear />
    <v-card elevation="0" class="pa-5 ma-2">
      <form @submit.prevent="sendForm" id="formBrand">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.name.$model"
              :error-messages="nameErrors"
              @blur="$v.name.$touch()"
              label="Nombre (*)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              outlined
              v-model="$v.description.$model"
              label="Descripción (*)"
              :error-messages="descriptionErrors"
              @blur="$v.description.$touch()"              
            >
            </v-textarea>
          </v-col>          
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-checkbox
              v-model="isDefault"
              label="Predeterminada"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-checkbox
              v-model="isPublic"
              label="Pública"
            ></v-checkbox>
          </v-col>
        </v-row>     
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Button
              :disabled="$v.$invalid"
              classes="mr-4 float-right fvl-btn green-primary"
              type="submit"
              name="Guardar"
            />
            <router-link :to="goToBack">
              <Button
                :disabled="false"
                classes="mr-4 float-right fvl-btn green-military"
                type="button"
                name="Volver"
              />
            </router-link>
          </v-col>
        </v-row>
        </v-row>
      </form>
      <Overlay />
    </v-card>
    <SnackBar />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { LoaderLinear, SnackBar, Overlay, Button } from "../_commons";
import { beginRequest, endRequest, links } from "../../helpers";

export default {
  name: "FormStep",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
    Button,
  },
  props: ["actionForm"],
  mixins: [validationMixin],
  validations: {
    name: { required, minLength: minLength(3) },
    description: { required, minLength: minLength(6), maxLength:maxLength(100) },
  },
  data() {
    return {
      goToBack: links.step.list,
      name: "",
      description: "",
      isDefault: false,
      isPublic: false,
    };
  },
  methods: {
    sendForm() {
      const is_default= this.isDefault ? 1 : 0;
      const is_public_by_default= this.isPublic ? 1 : 0;
      const formData = {
        name_step: this.name,
        description: this.description,        
        is_default,
        is_public_by_default,
      };
      this.$emit("sendForm", formData);
    },
    resetFormMethod() {
      this.name = "";
      this.description = "";
      this.isDefault = null;
      this.isPublic = null;     
      this.$v.$reset(); 
    },        
  },
  computed: {
    ...mapState({
      steps: (state) => state.steps,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.minLength &&
        errors.push("La longitud minima es de 6 caracteres");
      !this.$v.description.maxLength &&
        errors.push("La longitud máxima es de 100 caracteres");
      !this.$v.description.required &&
        errors.push("La descripción es requerida");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.name.required && errors.push("El nombre es requerido");
      return errors;
    },
  },
  async created() {
    if (this.actionForm === "Edit") {
      await beginRequest();
      const { snack, status } = await this.$store.dispatch("getStepById", this.$route.params.idstep);            
      if (this.steps.stepSelected) {
        this.name=this.steps.stepSelected.name;
        this.description=this.steps.stepSelected.description;
        this.isDefault=this.steps.stepSelected.is_default === 1 ? true : false;        
        this.isPublic=this.steps.stepSelected.is_public_by_default === 1 ? true : false;
      }
      await endRequest(snack, status);    
    }
  },
};
</script>