<template lang="">
  <v-container
    v-if="
      user.permissions.indexOf(permissionsAll.step_create) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <FormStep
      :actionForm="actionForm"
      @sendForm="sendForm"
      ref="formComponent"
    />
  </v-container>
</template>
<script>
import {
  beginRequest,
  endRequest,
  itemsBreadCrumbs,
  links,
} from "../../helpers";
import { BreadCrumbs } from "../../components/_commons";
import { FormStep } from "../../components/steps";
import router from "../../router";
import { mapState } from "vuex";
import permissions from "../../helpers/permissions";
export default {
  name: "StepCreate",
  components: {
    BreadCrumbs,
    FormStep,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.stepCreate,
      actionForm: "create",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async sendForm(formData) {
      await beginRequest();
      const { status, snack } = await this.$store.dispatch(
        "createStep",
        formData
      );
      if (status >= 200 && status < 300) {
        this.$refs.formComponent.resetFormMethod();
        setTimeout(() => {
          router.push(links.step.list);
        }, 2000);
      }
      await endRequest(snack, status);
    },
  },
  async created() {
    await this.$store.dispatch("setStepSelectedAction", null);
  },
};
</script>
